import { useEffect, useRef } from 'react';
import s from './AIVerse.module.css'

export default function AIVerseSection({ isHeroVideoLoaded }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isHeroVideoLoaded && videoRef != null && videoRef.current != null) {
      videoRef.current.play()
    }
  }, [isHeroVideoLoaded, videoRef]);

  return (
    <div className={s.sectionWrapper}>
      <video
          preload="auto"
          autobuffer="true"
          loop
          muted
          autoPlay
          playsInline
          ref={videoRef}
          className={s.video}
          style={{opacity: isHeroVideoLoaded ? '1' : '0'}}
      >
        <source
            src="https://humansdotai.fra1.cdn.digitaloceanspaces.com/evolve.mp4#t=0.1"
            type="video/mp4"
            data-wf-ignore="true"
        />
      </video>
      <div className={s.section}>
        <div className={s.title}>AI & Blockchain, owning the future</div>
        <div className={s.text}>
          Artificial Intelligence is a heavenly storm,
          <br /> but we are still in the pre-storm stage.
          <br />{' '}
          <span className={s.gray}>
            99% of the AIs that define our future are not yet fully developed.
          </span>
          <br />
          <span className={s.orange}>
            {' '}
            In our AIverse, we aim to include projects bold and mature enough to
            redefine our future reality by using AI.
          </span>{' '}
          <br />
          Invest in these unique AI projects – own and support part of the
          future.
          <br />
          Together, we're AIding humanity to benefit from the power of AI.
        </div>
        <img src="../cross.png" className={s.cross} alt="" />
      </div>
      <div className={s.line}></div>
    </div>
  )
}
