import s from './Community.module.css'

export default function CommunitySection() {
  return (
    <div className={s.sectionWrapper}>
      <div className={s.section}>
        <div className={s.left}>
          <div className={s.title}>
            Join our
            <br />
            <span>global</span>
            <br />
            community
          </div>
          <div className={s.text}>
            Tokens that you cannot recognise as software representative. Tokens
            that captures emotions, actions or the act of presence.
          </div>
        </div>
        <div className={s.right}>
          <div className={s.option}>
            <img src="../telegram.png" className={s.telegram} alt="" />
            <div className={s.optionText}>
              <div className={s.optionName}>Community Chat</div>
              <div className={s.optionText}>
                Join our Telegram channels:{' '}
                <a
                  href="https://t.me/humansdotai"
                  target="_blank"
                  rel="noreferrer"
                >
                  Humans
                </a>{' '}
                and{' '}
                <a
                  href="https://t.me/humansdotai_announcements"
                  target="_blank"
                  rel="noreferrer"
                >
                  Humans.AI Announcements
                </a>
              </div>
            </div>
          </div>
          <div className={s.option}>
            <img src="../twitter.png" className={s.twitter} alt="" />
            <div className={s.optionText}>
              <div className={s.optionName}>Twitter</div>
              <div className={s.optionText}>
                Check our{' '}
                <a
                  href="https://twitter.com/humansdotai"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </div>
            </div>
          </div>
          <div className={s.option}>
            <img src="../medium.png" className={s.medium} alt="" />
            <div className={s.optionText}>
              <div className={s.optionName}>Medium</div>
              <div className={s.optionText}>
                Learn more about us and what we do on{' '}
                <a
                  href="https://medium.com/@HumansAI"
                  target="_blank"
                  rel="noreferrer"
                >
                  Medium
                </a>
              </div>
            </div>
          </div>
          <div className={s.option}>
            <img src="../discord.png" className={s.medium} alt="" />
            <div className={s.optionText}>
              <div className={s.optionName}>Discord</div>
              <div className={s.optionText}>
                Join our community on discord channel{' '}
                <a
                    href="https://discord.gg/humansdotai"
                    target="_blank"
                    rel="noreferrer"
                >
                  Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
