import './App.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useEffect, useState, useRef, useCallback } from 'react';
import WhatIsArtificialCircleSection from './sections/WhatIsArtificialCircle.section';
import HumansScaleSection from './sections/HumansScale.section';
import IdeaSection from './sections/Idea.section';
import ProofOfHumanSection from './sections/ProofOfHuman.section';
import GovernanceSection from './sections/Governance.section';
import BlockchainSection from './sections/Blockchain.section';
import AIVerseSection from './sections/AIVerse.section';
import EcosystemSection from './sections/Ecosystem.section';
import CommunitySection from './sections/Community.section';
import FooterSection from './sections/Footer.section';
import SliderSection from './sections/Slider.section';
import ScalePotentialSection from './sections/ScalePotential.section';
import WebsiteSection from './sections/Website.section';

function App() {
  const heroVideoSrcDesktopPart1 =
    'https://humansdotai.fra1.cdn.digitaloceanspaces.com/motion_particule_1080p_part1.mp4';
  const heroVideoSrcDesktopPart2 =
    'https://humansdotai.fra1.cdn.digitaloceanspaces.com/motion_particule_1080p_part2.mp4';

  const videoRefPart1 = useRef();
  const videoRefPart2 = useRef();

  const [isHeroVideoPart1Loaded, setIsHeroVideoPart1Loaded] = useState(false);
  const [percentageVideoPart1Loaded, setPercentageVideoPart1Loaded] =
    useState(0);
  const [isHeroVideoPart2Loaded, setIsHeroVideoPart2Loaded] = useState(false);
  const [percentageVideoPart2Loaded, setPercentageVideoPart2Loaded] =
    useState(0);
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [isReload, setIsReload] = useState(false);

  /*
  const isMobile = useCallback(() => {
    return ( window.innerWidth <= 800 );
  }, []);
  */

  const play = useCallback(async () => {
    await videoRefPart1.current.play();
    await videoRefPart1.current.pause();
    videoRefPart1.current.currentTime = 0;

    await videoRefPart2.current.play();
    await videoRefPart2.current.pause();
    videoRefPart2.current.currentTime = 0;
  }, [videoRefPart1]);

  const disableScrolling = useCallback(() => {
    const x = window.scrollX;
    const y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }, []);

  const enableScrolling = useCallback(() => {
    window.onscroll = function () { };
  }, []);

  const scrollTop = useCallback(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    if (window.scrollY > 0 && !isScrollTop) {
      window.scrollTo({ top: 0 });
      setIsScrollTop(true);
      ScrollTrigger.refresh(true);
    }
    if (window.scrollY === 0 && isScrollTop && !isReload) {
      window.location.reload();
      setIsReload(true);
    }
  }, [isScrollTop, setIsScrollTop, isReload, setIsReload]);

  const scrollToWebsite = useCallback(() => {
    const access = document.getElementById('jumper');
    access.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const scrollTo3Seconds = useCallback(() => {
    window.scroll({
      top: 1000,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const scrollTrigger = useCallback(() => {
    if (isHeroVideoPart1Loaded && isHeroVideoPart2Loaded) {
      gsap.registerPlugin(ScrollTrigger);

      const websiteSection = document.getElementById('website-section');
      const coolVideoPart1 = document.getElementById('video-part1');
      const coolVideoPart2 = document.getElementById('video-part2');
      const jumpLink = document.getElementById('jump-link');
      const header = document.getElementById('header');
      const text = document.getElementById('text');
      const circleBg = document.getElementById('bg-circle');
      const circleBgPotential = document.getElementById('bg-circle-potential');
      const circleBgPotentialGreen = document.getElementById(
        'bg-circle-potential-green'
      );

      function once(el, event, fn, opts) {
        const onceFn = function (e) {
          el.removeEventListener(event, onceFn);
          fn.apply(this, arguments);
        };
        el.addEventListener(event, onceFn, opts);
        return onceFn;
      }

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: 'video',
          start: 'top top',
          end: '+=1100%',
          scrub: true,
          pinSpacing: 'margin',
          onUpdate: self => {
            coolVideoPart1.currentTime =
              (coolVideoPart1.duration + coolVideoPart2.duration) *
              self.progress;
            coolVideoPart2.currentTime =
              (coolVideoPart1.duration + coolVideoPart2.duration) *
              (self.progress - 0.5);
          },
        },
      });

      once(document.documentElement, 'touchstart', function () {
        videoRefPart1.current.play();
        videoRefPart1.current.pause();
      });

      once(document.documentElement, 'touchstart', function () {
        videoRefPart2.current.play();
        videoRefPart2.current.pause();
      });

      let tlBgCircle = gsap.timeline({
        scrollTrigger: {
          trigger: '#bg-circle',
          start: 'top bottom',
          end: '+=100%',
          scrub: true,
          pinSpacing: 'margin',
        },
      });

      let tlPotentialCircle = gsap.timeline({
        scrollTrigger: {
          trigger: '#bg-circle-potential',
          start: 'top bottom',
          end: '+=100%',
          scrub: true,
          pinSpacing: 'margin',
        },
      });

      const texts = gsap.utils.toArray('.text-p');
      texts.forEach((text, index) => {
        if (index === 5) {
          tl.from(text, { opacity: 0, duration: 10 })
            .to(text, { opacity: 1, duration: 30 })
            .to(text, { opacity: 1, duration: 30 });
        } else {
          tl.from(text, { opacity: 0, duration: 10 })
            .to(text, { opacity: 1, duration: 30 })
            .to(text, { opacity: 0, duration: 30 });
        }
      });

      tl.to(
        text,
        {
          duration: 5,
          opacity: 0,
        },
        tl.duration() - 5
      );

      tl.to(
        jumpLink,
        {
          opacity: 0.4,
          duration: 1,
        },
        1
      );

      tl.to(
        jumpLink,
        {
          opacity: 0,
          duration: 1,
          zIndex: 1,
        },
        tl.duration() - 60
      );

      tl.to(
        header,
        {
          opacity: 0,
          duration: 5,
          zIndex: 1,
        },
        tl.duration() - 5
      );

      tl.to(
        websiteSection,
        {
          position: 'fixed',
          opacity: 0,
          duration: 1,
        },
        1
      );

      tl.to(
        websiteSection,
        {
          duration: 5,
          opacity: 0,
        },
        tl.duration() - 5
      );

      tl.to(
        websiteSection,
        {
          opacity: 1,
          duration: 10,
        },
        tl.duration() - 60
      );

      tl.to(
        coolVideoPart1,
        {
          duration: 5,
          display: 'none',
          opacity: 0,
        },
        tl.duration() / 2 - 5
      );

      tl.to(
        coolVideoPart2,
        {
          duration: 0,
          opacity: 0,
        },
        0
      );

      tl.to(
        coolVideoPart2,
        {
          duration: 0,
          display: 'flex',
          opacity: 1,
        },
        tl.duration() / 2 - 4
      );

      tl.to(
        coolVideoPart2,
        {
          duration: 10,
          opacity: 0,
        },
        tl.duration() - 10
      );

      tl.reverse();

      tlBgCircle.to(
        circleBg,
        {
          duration: 10,
          rotation: 360,
          scaleX: 1.3,
          skewY: '1deg',
          skewX: '1deg',
        },
        tlBgCircle.duration()
      );

      tlPotentialCircle.to(
        circleBgPotential,
        {
          duration: 10,
          rotation: 360,
          scaleX: 1.4,
          skewX: '1deg',
        },
        tlPotentialCircle.duration()
      );

      tlPotentialCircle.to(
        circleBgPotentialGreen,
        {
          duration: 10,
          rotation: 360,
          scaleX: 1.4,
          skewX: '1deg',
        },
        tlPotentialCircle.duration()
      );
    }
  }, [isHeroVideoPart1Loaded, isHeroVideoPart2Loaded]);

  useEffect(() => {
    const f = function () {
      if (!isHeroVideoPart1Loaded) {
        let range = 0;
        const bf = this.buffered;

        if (bf.length) {
          const loadStartPercentage = bf.start(range) / this.duration;
          const loadEndPercentage = bf.end(range) / this.duration;
          const loadPercentage = loadEndPercentage - loadStartPercentage;
          const loadPercentageFormat =
            Math.round(loadPercentage * 100 * 100) / 100;
          setPercentageVideoPart1Loaded(loadPercentageFormat);
          if (
            loadPercentageFormat < 75 &&
            bf.end(range) > videoRefPart1.current.currentTime
          ) {
            videoRefPart1.current.currentTime = bf.end(range) - 1;
            videoRefPart1.current.play();
          } else if (loadPercentageFormat >= 75) {
            videoRefPart1.current.currentTime = 0;
            videoRefPart1.current.pause();
            setTimeout(() => {
              setIsHeroVideoPart1Loaded(true);
            }, 1000);
          }
        }
      }
    };
    const localVideoRef = videoRefPart1.current;
    if (videoRefPart1 !== null && videoRefPart1.current !== null) {
      videoRefPart1.current.addEventListener('progress', f);
    }
    return () => {
      localVideoRef.removeEventListener('progress', f);
    };
  }, [
    isHeroVideoPart1Loaded,
    setIsHeroVideoPart1Loaded,
    setPercentageVideoPart1Loaded,
    videoRefPart1,
  ]);

  useEffect(() => {
    const f = function () {
      if (!isHeroVideoPart2Loaded) {
        let range = 0;
        const bf = this.buffered;

        if (bf.length) {
          const loadStartPercentage = bf.start(range) / this.duration;
          const loadEndPercentage = bf.end(range) / this.duration;
          const loadPercentage = loadEndPercentage - loadStartPercentage;
          const loadPercentageFormat =
            Math.round(loadPercentage * 100 * 100) / 100;
          setPercentageVideoPart2Loaded(loadPercentageFormat);
          if (
            loadPercentageFormat < 75 &&
            bf.end(range) > videoRefPart2.current.currentTime
          ) {
            videoRefPart2.current.currentTime = bf.end(range) - 1;
            videoRefPart2.current.play();
          } else if (loadPercentageFormat >= 75) {
            videoRefPart2.current.currentTime = 0;
            videoRefPart2.current.pause();
            setTimeout(() => {
              setIsHeroVideoPart2Loaded(true);
            }, 1000);
          }
        }
      }
    };
    const localVideoRef = videoRefPart2.current;
    if (videoRefPart2 !== null && videoRefPart2.current !== null) {
      videoRefPart2.current.addEventListener('progress', f);
    }
    return () => {
      localVideoRef.removeEventListener('progress', f);
    };
  }, [
    isHeroVideoPart2Loaded,
    setIsHeroVideoPart2Loaded,
    setPercentageVideoPart2Loaded,
    videoRefPart2,
  ]);

  useEffect(() => {
    scrollTrigger();
  }, [scrollTrigger]);

  useEffect(() => {
    if (isHeroVideoPart1Loaded && isHeroVideoPart2Loaded) {
      scrollTop();
      enableScrolling();
      play();
      scrollTo3Seconds();
    } else {
      disableScrolling();
    }
  }, [
    isHeroVideoPart1Loaded,
    isHeroVideoPart2Loaded,
    scrollTop,
    enableScrolling,
    disableScrolling,
    play,
    scrollTrigger,
    scrollTo3Seconds,
  ]);

  return (
    <div>
      <div className="main" id="intro" style={{ position: 'relative' }}>
        <header id="header" className="tovid-header">
          <div className="humans-container">
            <div className="header">
              <a href="/" className="tovid-logo">
                <img src="/logo-humans.svg" alt="Humans" />
              </a>
              <div id="menuToggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu-top-menu" className="mj-meniu">
                  <img
                    className="black_logo"
                    src="../images/Humans-black.png"
                    alt=""
                  />
                  <li className="mj-meniu__item">
                    <a
                      href="/"
                      className="mj-meniu__item__link mj-meniu__item__link--active">
                      Home
                    </a>
                  </li>
                  <li className="mj-meniu__item">
                    <a href="/proof-of-human" className="mj-meniu__item__link">
                      Proof of Human
                    </a>
                  </li>
                  <li className="mj-meniu__item">
                    <a href="/ai" className="mj-meniu__item__link">
                      AI
                    </a>
                  </li>
                  <li className="mj-meniu__item">
                    <a href="/blockchain" className="mj-meniu__item__link">
                      Blockchain
                    </a>
                  </li>
                  <li className="mj-meniu__item">
                    <a href="/heart" className="mj-meniu__item__link">
                      $Heart
                    </a>
                  </li>
                  <li className="mj-meniu__item">
                    <a
                      href="https://scale.humans.ai/"
                      className="mj-meniu__item__link">
                      Scale
                    </a>
                  </li>
                  {/* <li className="mj-meniu__item">
                    <a
                      href="https://staking.humans.ai/app/marketplace"
                      className="mj-meniu__item__link">
                      Staking
                    </a>
                  </li> */}
                  <li className="mj-meniu__item">
                    <a
                      href="https://blog.humans.ai/"
                      target="_blank"
                      className="mj-meniu__item__link"
                      rel="noreferrer">
                      Blog
                    </a>
                  </li>
                  <li className="mj-meniu__item">
                    <a
                      href="https://blog.humans.ai/media-archive/"
                      target="_blank"
                      className="mj-meniu__item__link"
                      rel="noreferrer">
                      Press
                    </a>
                  </li>
                </ul>
                <ul></ul>
              </div>
            </div>
          </div>
        </header>
        {(!isHeroVideoPart1Loaded || !isHeroVideoPart2Loaded) && (
          <div className="loader-container">
            <div className="loader">&nbsp;</div>
            <div className="loader-percent">
              <span>
                {Math.ceil(
                  (percentageVideoPart1Loaded + percentageVideoPart2Loaded) / 2
                )}
                %
              </span>
            </div>
          </div>
        )}
        <div className="videoWrapper">
          <video
            preload="auto"
            autobuffer="true"
            loop
            muted
            autoPlay
            playsInline
            ref={videoRefPart1}
            className="video video-part1"
            style={{ display: isHeroVideoPart1Loaded ? 'flex' : 'none' }}
            id="video-part1">
            <source
              src={heroVideoSrcDesktopPart1}
              type="video/mp4"
              data-wf-ignore="true"
            />
          </video>
          <video
            preload="auto"
            autobuffer="true"
            loop
            muted
            autoPlay
            playsInline
            ref={videoRefPart2}
            className="video video-part2"
            style={{ display: 'none' }}
            id="video-part2">
            <source
              src={heroVideoSrcDesktopPart2}
              type="video/mp4"
              data-wf-ignore="true"
            />
          </video>
        </div>
        <div className="videoOverlay">&nbsp;</div>
        {isHeroVideoPart1Loaded && isHeroVideoPart2Loaded && (
          <>
            <div className="text" id="text">
              <p className="text-p">Building the AIverse</p>
              <p className="text-p">
                AI is the most powerful tool ever invented
              </p>
              <p className="text-p">
                That can help you create anything you imagine
              </p>
              <p className="text-p">
                Speak in any language, clone yourself, expand your knowledge
              </p>
              <p className="text-p">
                Only if it's contained in an ethical framework
              </p>
              <p className="text-p">
                Enter the blockchain of AIs: <br />
                The AIverse
              </p>
            </div>
            <a
              href="#ai"
              className="jump-link"
              id="jump-link"
              onClick={scrollToWebsite}>
              SCROLL TO DISCOVER OR{' '}
              <span className="jump-href">JUMP TO WEBSITE</span>
              <br />
              <img src="/scroll-down.png" alt="Scroll down" />
            </a>
          </>
        )}
      </div>
      <div id="jumper"></div>
      {isHeroVideoPart1Loaded && isHeroVideoPart2Loaded && (
        <>
          <WebsiteSection />
          <div id="website-after">
            <SliderSection />
            <WhatIsArtificialCircleSection />
            <ScalePotentialSection />
            <HumansScaleSection />
            <IdeaSection
              isHeroVideoLoaded={
                isHeroVideoPart1Loaded && isHeroVideoPart2Loaded
              }
            />
            <ProofOfHumanSection />
            <GovernanceSection />
            <BlockchainSection />
            <AIVerseSection
              isHeroVideoLoaded={
                isHeroVideoPart1Loaded && isHeroVideoPart2Loaded
              }
            />
            <EcosystemSection />
            <CommunitySection />
            <FooterSection />
          </div>
        </>
      )}
    </div>
  );
}

export default App;
