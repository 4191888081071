import s from './Ecosystem.module.css';

export default function EcosystemSection() {
    return <div className={s.sectionWrapper}>
        <div className={s.section}>
            <div className={s.title}>Humans <span>AIverse</span> Ecosystem</div>
            <div className={s.text}>
                Between the world as we know it (earth) and the stars we're gazing at (Solar System &
                beyond), there is a place where imagination meets technology, making things possible.
                Palpable. Profoundly humane, yet more aspirational than ever.
            </div>
        </div>
    </div>;
}
