import s from './HumansScale.module.css';
import cx from 'classnames';

export default function HumansScaleSection() {
    return <div className={s.sectionWrapper}>
        <div className={s.section}>
            <a href="https://scale.humans.ai/"  target="_blank" rel="noreferrer"><img src="../humans-scale.png" className={s.logo} alt="" /></a>
            <div className={s.title}>
                <img src="../img-heart.png" className={s.heart} alt="" />
                Own an AI<br />or be part of one
            </div>
            <div className={s.subTitle}>Join the first AI Launchpad in the world</div>
            <div className={s.list}>
                <div className={s.listItem}>
                    <img src="../img-ai-nft.png" alt="" />
                    <div className={s.lineItemTitle}>
                        <u>Get</u><br />
                        an AI NFT
                    </div>
                    <div className={s.lineItemText}>Scale yourself with AI NFTs</div>
                </div>
                <div className={s.listItem}>
                    <img src="../img-ai-project.png" alt="" />
                    <div className={s.lineItemTitle}>
                        <a href="https://scale.humans.ai/invest" target="_blank" rel="noreferrer">Invest</a> <br />
                        AI projects
                    </div>
                    <div className={s.lineItemText}>Be part of ideas of tomorrow</div>
                </div>
                <div className={s.customItem}>
                    <div className={s.label}>Be part of ideas of tomorrow</div>
                    <div className={s.customItemTitle}>
                    Bring your AI <br />
                    to Web3
                    </div>
                    <a href="https://humansdotai.typeform.com/to/gGZnDpBl" className={cx("link", "small-link", "human-scale-bottom-link")}  target="_blank" rel="noreferrer">Apply to join Scale</a>
                </div>
            </div>
        </div>
    </div>;
}
