import s from './style.module.css'
import cx from 'classnames'

export default function WhatIsArtificialCircleSection() {
  return (
    <div className={s.section} id="what-is">
      <span className={s.textContainer}>
        <div className={s.bullet} />
        <div className="row color-white">
          What is
          <span className="color-primary mx-8">
            artificial about intelligence?
          </span>
          <span className={s.centerResponse}>The framework.</span>
        </div>
        <div className={cx('row', s.afterResponse)}>
          <span className="color-white">
            We are reinventing it while placing your needs at the
            face front of the AI evolution
            <span className={cx('color-primary', s.colorPrimary)}>
              {' '}
              - scaling humans' potential like never before.
            </span>
          </span>
        </div>
        <div className={s.bgCircle} id="bg-circle"></div>
        <div className={s.circleBig}></div>
        <div className={s.circleSmall} id="circle-small">
          <p className={s.circleSmallText}>
            <a href="/blockchain">EXPLORE</a>
          </p>
        </div>
      </span>
    </div>
  )
}
