import s from './ProofOfHuman.module.css'
import cx from 'classnames'

export default function ProofOfHumanSection() {
  return (
    <div className={s.sectionWrapper}>
      <div className={s.section}>
        <div className={s.left}>
          <img src="../proof.png" className={s.logo} alt="" />
          <div className={s.bigText}>You need to be a human first</div>
          <div className={s.smallText}>
            The perfect technological framework for AIs to be created and used
          </div>
          <a href="/proof-of-human" className={s.button} target="_blank" rel="noreferrer">
            Read more
          </a>
        </div>
        <div className={s.right}>
          <div className={s.phone}>
            <img src="../coin.png" className={s.coin} alt="" />
            <div className={s.totalText}>TOTAL BALANCE</div>
            <div className={s.amount}>7.234.234</div>
            <div className={s.token}>$HEART</div>
            <a href="/heart" className={cx('link', s.link)} target="_blank" rel="noreferrer">
              Send
            </a>
            <img src="../my-ai-nft.png" className={s.myAiNft} alt="" />
            <img src="../lips.png" className={s.lips} alt="" />
            <div className={s.bottom}>
              <a href="/" className={s.aiNFT}>
                AI NFT
              </a>
              <a href="/" className={s.wallet}>
                WALLET
              </a>
              <a href="/" className={s.profile}>
                PROFILE
              </a>
            </div>
          </div>
          <div className={s.stores}>
            <a href="/">
              <img src="../app-store.png" alt="" />
            </a>
            <a href="/">
              <img src="../google-play.png" alt="" />
            </a>
          </div>
          <img src="../hand.png" className={s.hand} alt="" />
        </div>
      </div>
    </div>
  )
}
