import s from './Governance.module.css'

export default function GovernanceSection() {
  return (
    <div className={s.section}>
      <div className={s.title}>
        Consensus and verification in the era of trust
      </div>
      <div className={s.article}>
        <div className={s.leftText}>
          <div className={s.whiteText}>
            We are entering an era of continuous disruption.
          </div>
          <span className={s.grayText}>
            PoH is a complex and versatile mechanism that ensures three key
            functions related to AI NFTs: governance, consensus and
            verification.
          </span>
        </div>
        <div className={s.rightText}>
          <span className={s.grayText}>
            People can interact directly with the Humans.ai blockchain and the
            PoH mechanism via smartwatch or smartphone through specialized
            applications.
          </span>
          <a
            href="https://medium.com/humansdotai/humans-ai-presents-proof-of-human-d88bcec585dc"
            className={s.link}
          >
            Read the full article
          </a>
        </div>
      </div>
      <div className={s.footer}>
        To ensure the governance of an AI NFT, Humans.ai utilizes Proof-of-Human
        to confirm that behind every request is a real human.
        <div className={s.amount}>7.922.402.27</div>
        <div className={s.label}>Current world population</div>
      </div>
    </div>
  )
}
