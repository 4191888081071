import s from './Blockchain.module.css'
import cx from 'classnames'
import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";

export default function BlockchainSection(props) {
    const isHeroVideoLoaded = props.isHeroVideoLoaded === true;
    const videoRef = useRef(null);
    const [height, setHeight] = useState('840530');
    const [bonded, setBonded] = useState('17.7');
    const [supply, setSupply] = useState('57.57');
    const [boundedPercent, setBoundedPercent] = useState('34.6');
    const [inflation, setInflation] = useState('8.7');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (isHeroVideoLoaded && videoRef != null && videoRef.current != null) {
            videoRef.current.play()
        }
    }, [isHeroVideoLoaded, videoRef])
    const setVideoLoaded = useCallback(() => {
        setLoading(false)
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const respInflation = await axios(`https://api-testnet.humans.zone/cosmos/mint/v1beta1/inflation`);
            const respPool = await axios(`https://api-testnet.humans.zone/cosmos/staking/v1beta1/pool`);
            const respSupply = await axios(`https://api-testnet.humans.zone/cosmos/bank/v1beta1/supply/by_denom?denom=uheart`);
            const respHeight = await axios(`https://api-testnet.humans.zone/cosmos/base/tendermint/v1beta1/blocks/latest`);
            if(respInflation.data?.inflation > 0) {
                setInflation(Math.round(respInflation.data?.inflation * 100 * 100) / 100);
            }
            if(respPool.data?.pool?.bonded_tokens > 0) {
                setBonded(Math.round(respPool.data?.pool?.bonded_tokens/10000000000) / 100);
            }
            if(respSupply.data?.amount?.amount > 0) {
                setSupply(Math.round((respSupply.data?.amount?.amount)/10000000000) / 100);
            }
            if(respPool.data?.pool?.bonded_tokens > 0 && respSupply.data?.amount?.amount > 0) {
                setBoundedPercent(Math.round(respPool.data?.pool?.bonded_tokens / respSupply.data?.amount?.amount * 10000) / 100);
            }
            if(respHeight.data?.block?.last_commit?.height > 0) {
                setHeight(respHeight.data?.block?.last_commit?.height);
            }
        };

        fetchData();

    }, []);
    return (<div className={s.sectionWrapper}>
            {loading && <div></div>}
            <video
                preload="auto"
                autobuffer="true"
                data-wf-ignore="true"
                loop
                muted
                autoPlay
                playsInline
                ref={videoRef}
                className={s.video}
                onLoadedData={setVideoLoaded}
            >
                <source
                    src="https://humansdotai.fra1.cdn.digitaloceanspaces.com/glass.mp4#t=0.1"
                    type="video/mp4"
                    data-wf-ignore="true"
                />
            </video>
            <div className={s.section}>
                <div className={s.status}><a href="https://explorer.humans.zone/" target="_blank" rel="noreferrer">TESTNET LIVE</a></div>
                <img src="../img-blockchain.png" className={s.logo} alt=""/>
                <div className={s.title}>
                    The safest framework: the Blockchain for AIs
                </div>
                <div className={s.text}>
                    Step on the soil of our AIverse - the framework that offers everyone
                    the ethical environment to create, own, or use artificial intelligence
                    for bringing digital life to the next level. Blockchain for AI brings
                    scalability, security, and interoperability to develop technology for
                    future stages.
                </div>
                <a href="/validators" className={cx('link', s.link)}>
                    Be a validator
                </a>
                <ul className={s.list}>
                    <li>
                        <img src="../height.png" alt=""/>
                        <div className={s.listText}>
                            <div className={s.label}>Height</div>
                            <div className={s.value}>{height}</div>
                        </div>
                    </li>
                    <li>
                        <img src="../bonded.png" alt=""/>
                        <div className={s.listText}>
                            <div className={s.label}>Bonded and Supply</div>
                            <div className={s.value}>{bonded}M / {supply}M</div>
                        </div>
                    </li>
                    <li>
                        <img src="../ratio.png" alt=""/>
                        <div className={s.listText}>
                            <div className={s.label}>Bonded Ratio</div>
                            <div className={s.value}>{boundedPercent}%</div>
                        </div>
                    </li>
                    <li>
                        <img src="../inflation.png" alt=""/>
                        <div className={s.listText}>
                            <div className={s.label}>Inflation</div>
                            <div className={s.value}>{inflation}%</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
