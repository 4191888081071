import s from './Footer.module.css'

export default function FooterSection() {
  return (
    <div className={s.sectionWrapper}>
      <div className={s.section}>
        <div className={s.menus}>
          <div className={s.menu}>
            <div className={s.title}>Technology</div>
            <div className={s.links}>
              <a href="/blockchain">
                Blockchain
                <img src="../arrow-footer.png" alt="" />
              </a>
              <div className={s.disabledLink}>Explorer</div>
              <div className={s.disabledLink}>Security</div>
              {/* <a
                href="https://staking.humans.ai/app/marketplace"
                target="_blank"
                rel="noreferrer"
              >
                Staking <img src="../arrow-footer.png" alt="" />
              </a> */}
              <div className={s.disabledLink}>Bridge</div>
              <a href="/proof-of-human">Proof of Human</a>
            </div>
          </div>
          <div className={s.menu}>
            <div className={s.title}>AI</div>
            <div className={s.links}>
              <div className={s.disabledLink}>Thesis</div>
              <a href="/ai">Experiments</a>
            </div>
          </div>
          <div className={s.menu}>
            <div className={s.title}>Join Humans</div>
            <div className={s.links}>
              <a href="/validators">Validators</a>
              <a href="/investors">Investors</a>
              <div className={s.disabledLink}>Carreers</div>
              <div className={s.disabledLink}>Events</div>
            </div>
          </div>
          <div className={s.menu}>
            <div className={s.title}>Launchpad</div>
            <div className={s.links}>
              <a href="https://scale.humans.ai/" target="_blank" rel="noreferrer">
                Scale
                <img src="../arrow-footer.png" alt="" />
              </a>
              <a href="https://talkens.ai/" target="_blank" rel="noreferrer">
                Talkens
                <img src="../arrow-footer.png" alt="" />
              </a>
              <a href="https://tovid.ai/" target="_blank" rel="noreferrer">
                Tovid
                <img src="../arrow-footer.png" alt="" />
              </a>
              <div className={s.disabledLink}>Use Cases</div>
            </div>
          </div>
          <div className={s.menu}>
            <div className={s.title}>Ecosystem</div>
            <div className={s.links}>
              <a href="/team">Team</a>
              <div className={s.disabledLink}>Community</div>
              <div className={s.disabledLink}>Partners</div>
              <a href="/heart">$Heart</a>
              <div className={s.disabledLink}>Thoughts</div>
              <a href="/litepaper">Litepaper</a>
              <a href="/AIverse_Thesis_2023.pdf" target="_blank" rel="noreferrer">
                AIverse Thesis
                <img src="../arrow-footer.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className={s.footerBottom}>
          <a href="https://humans.ai">
            <img src="../footer-logo.png" alt="" />
          </a>
          <a
            className={s.footerBottomLink}
            href="/terms-of-use"
          >
            Terms of use
          </a>
          <a
            className={s.footerBottomLink}
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          <a
            className={s.footerBottomLink}
            href="https://drive.google.com/file/d/1EzzwfHQKTW63GkksqKxN4LRBZOXk04sm/view"
            target="_blank"
            rel="noreferrer"
          >
            Litepaper
            <img src="../arrow-footer.png" alt="" />
          </a>
          <div className={s.social}>
            <a
              href="https://medium.com/humansdotai"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../footer-medium.png" alt="" />
            </a>
            <a
              href="https://twitter.com/humansdotai"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../footer-twitter.png" alt="" />
            </a>
            <a href="https://t.me/humansdotai" target="_blank" rel="noreferrer">
              <img src="../footer-telegram.png" alt="" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCRPyI284yy0FWWz9YEWx2XQ"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../footer-youtube.png" alt="" />
            </a>
            <a
              href="https://www.instagram.com/humansdotai/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../footer-instagram.png" alt="" />
            </a>
            <a
              href="https://discord.gg/humansdotai/"
              target="_blank"
              rel="noreferrer"
              className={s.footerDiscordLink}
            >
              <img src="../images/footer-discord.png" alt="" />
            </a>
          </div>
        </div>
        <div className={s.copyright}>
          Copyright © 2022 Humans Token AG. All rights reserved.
        </div>
      </div>
    </div>
  )
}
