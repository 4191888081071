import s from './Website.module.css';

export default function WebsiteSection() {

    return <div id="website-section" className={s.sectionWrapper}>
      <div className={s.section}>
          <div className={s.videoWrapper}></div>
          <div className={s.text} id="website-text">
                <a href="https://humans.ai/heart" className={s.button2} target="_blank" rel="noreferrer">Learn about $Heart</a>
              <a href="https://www.youtube.com/watch?v=-wUyPSQSdXA&ab_channel=Humans" className={s.button} target="_blank" rel="noreferrer">See the manifesto</a>

              <div className={s.partners}>
                  <a href="https://hedera.com/" target="_blank" rel="noreferrer" className={s.partner}>
                      <img src="/Hedera.png" alt="" className={s.hedera} />
                  </a>
                  <a href="https://morningstar.ventures/" target="_blank" rel="noreferrer" className={s.partner}>
                      <img src="/Morningstar.png" alt="" className={s.morningstar} />
                  </a>
                  <a href="https://chymia.io/" target="_blank" rel="noreferrer" className={s.partner}>
                      <img src="/NextChymia.png" alt="" className={s.chymia}/>
                  </a>
                  <a href="https://www.kucoin.com/" target="_blank" rel="noreferrer" className={s.partner}>
                      <img src="/Kukoin.png" alt="" className={s.kukoin}/>
                  </a>
                  <a href="https://www.finchcapital.com/" target="_blank" rel="noreferrer" className={s.partner}>
                      <img src="/FinchCapital.png" alt="" className={s.finchcapital}/>
                  </a>
                  <a href="https://polkastartercom.com/" target="_blank" rel="noreferrer" className={s.partner}>
                      <img src="/Polkastarer.png" alt="" className={s.polkastarter}/>
                  </a>
              </div>
          </div>
      </div>
    </div>;
}
