import cx from 'classnames'
import s from './ScalePotential.module.css'

export default function ScalePotentialSection() {
  const elements = [
    {
      title: ['Speak ', 'in any language'],
      videoLink: '../../lips.mp4#t=0.1',
      scale: 85,
      ctaText: 'Get yours',
      targetLink: 'https://scale.humans.ai/project/speak-in-any-language',
      targetBlank: true,
    },
    {
      title: ['Create the Web3 ', 'voice of your community'],
      videoLink: '../../ball.mp4#t=0.1',
      scale: 130,
      ctaText: 'See example',
      targetLink: 'https://scale.humans.ai/project/voice-of-community',
      targetBlank: true,
    },
    {
      title: ['Create your ', 'Metaverse Avatar'],
      videoLink: '../../diana.mp4',
      scale: 90,
      ctaText: 'Get yours',
      targetLink: 'https://scale.humans.ai/project/tovid-ai',
      targetBlank: true,
    },
    {
      title: ['Bring your ', 'NFT to life'],
      videoLink: '../../monkey.mp4#t=0.1',
      scale: 85,
      cropInCircle: true,
      ctaText: 'Get yours',
      targetLink: 'https://scale.humans.ai/project/talkens-ai',
      targetBlank: true,
    },
  ]

  return (
    <div className={s.section} id="scale-potential">
      <div className={s.logoContainerPotential}>
        <a href="https://scale.humans.ai/"  target="_blank" rel="noreferrer"><img src="../../logo-section-scale.png" alt="" /></a>
      </div>

      <span className={s.textContainerPotential}>
        <div className="row color-white pt-24">
          <img
            src="../img-heart-humans-scale.png"
            className={s.titleHeart}
            alt=""
          />
          AI can scale your potential
        </div>
        <div className={cx(['row color-white', s.subTextPotential])}>
          Get early access to the ideas of tomorrow
        </div>
        <div className={s.bgCirclePotential} id="bg-circle-potential"></div>
        <div
          className={s.bgCirclePotentialGreen}
          id="bg-circle-potential-green"
        ></div>
      </span>
      <div className={s.gifOuterWrapper}>
        <div className={s.gifContainer}>
          {elements &&
            elements.map((element, i) => {
              return (
                <div
                  className={cx(['flex', s.nftOuterWrapper])}
                  key={element + i}
                >
                  <div>
                    <img src="../../nft.png" alt="" className={s.nftLogo} />
                    <div className={s.gifContainerItem}>
                      {element.videoLink ? (
                        <>
                          <video
                            preload="auto"
                            autobuffer="true"
                            loop
                            muted
                            autoPlay
                            playsInline
                            className={cx(s.elementVideo, element.cropInCircle && s.roundVideo)}
                            width={300}
                            height={300}
                            style={{'transform': `scale(${element.scale}%)`}}
                          >
                            <source
                                src={element.videoLink}
                                type="video/mp4"
                                data-wf-ignore="true"
                            />
                          </video>
                        </>
                      ) : (
                        element.gifLink && (
                          <>
                            <img
                              src={element.gifLink}
                              alt="gif1"
                              data-noaft="1"
                              className={s.gifImage}
                            />
                          </>
                        )
                      )}
                      <div className={s.circleGifSmall}></div>
                      <div className={s.circleGifBig1}></div>
                      <div className={s.circleGifBig2}></div>
                    </div>
                    <div className={cx(['row color-white', s.gifText])}>
                      {element.title.map((line, index) => {
                        if (index > 0)
                          return (
                            <span key={index + line}>
                              <br />
                              {line}
                            </span>
                          )
                        return <span key={index + line}>{line}</span>
                      })}
                    </div>
                  </div>
                  <a
                    href={element.targetLink}
                    target={element.targetBlank ? '_blank' : ''}
                    className={s.gifLinkContainer}
                    rel="noreferrer"
                  >
                    <div className="row color-white">{element.ctaText}</div>
                    <img
                      src="../../arrow-right.png"
                      alt=""
                      className={s.arrowRight}
                    />
                  </a>
                </div>
              )
            })}
        </div>
      </div>
      <div className={s.scaleLinkContainer}>
        <a href="https://scale.humans.ai/" className={cx(s.scaleLink, 'link', 'small-link')} target="_blank" rel="noreferrer">
          Discover projects on Scale
        </a>
      </div>
    </div>
  )
}
