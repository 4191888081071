
import s from "./Slider.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useEffect, useState} from "react";
import axios from "axios";

export default function SliderSection() {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
          partialVisibilityGutter: 0
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          partialVisibilityGutter: 0
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          partialVisibilityGutter: 0
        }
    };

    const defaultArticles = [{
        link: "https://medium.com/humansdotai/humans-ai-one-year-staking-program-coming-to-an-end-as-mainnet-launch-approaches-4e60fe7e0c4c",
        thumbnail: "../humans-staking-1.webp",
        title: "Humans.ai Staking Program Update, as Mainnet Launch Approaches",
        currentDate: '3 FEB 2023'
    }, {
        link: "https://medium.com/humansdotai/humans-ai-launches-wrapped-ai-nfts-on-hedera-3afdbdb9e2ff",
        thumbnail: "../humans-hedera-1.jpg",
        title: "Humans.ai launches wrapped AI NFTs on Hedera",
        currentDate: '3 FEB 2023'
    }, {
        link: "https://medium.com/humansdotai/the-humans-delegator-guidebook-457707ceb130",
        thumbnail: "../humans-delegator.webp",
        title: "The Humans Delegator Guidebook",
        currentDate: '2 FEB 2023'
    }];

    const fetchOnline = false;

    let [c, setC] = useState(null);
    const [selected, setSelected] = useState(1);
    const [articles, setArticles] = useState(defaultArticles);

    const fetchData = async () => {
        const maxArticles = 3;
        const mediumUrl = `https://medium.com/feed/humansdotai`;
        const feed = await axios(`https://api.rss2json.com/v1/api.json?rss_url=${mediumUrl}`);

        if(feed && feed.data?.items?.length > 0){
            const articleList = feed.data.items?.filter((item) => item.thumbnail)
                .slice(0, maxArticles)
                .map((item) => {
                    return {
                        link: item.link,
                        title: item.title,
                        thumbnail: item.thumbnail,
                        defaultArticle: "no-default",
                        pubDate: item.pubDate,
                        currentDate: new Date(item.pubDate).getDate() + " "
                            + new Date(item.pubDate).toLocaleString("default", { month: "short" }) + " "
                            + new Date(item.pubDate).getFullYear()
                    };
                });
            setArticles(articleList);
        }
    };

    useEffect(() => {
        if(fetchOnline) {
            fetchData();
        }
    }, [fetchOnline, setArticles]);

    return <div className={s.section}>
            <div className={s.titleWrapper}>
                <div className={s.label}>HUMANS DOT AI</div>
                <div className={s.title}>
                    Building the <span>AIverse</span>.<br />
                    It"s happening.
                </div>
                <div className={s.bullets}>
                    <div className={selected !== 1 ? s.bullet : s.selectedBullet} onClick={() => {
                        c.goToSlide(1)
                        setSelected(1);
                    }}>&nbsp;</div>
                    <div className={selected !== 2 ? s.bullet : s.selectedBullet} onClick={() => {
                        c.goToSlide(2);
                        setSelected(2);
                    }}>&nbsp;</div>
                    <div className={selected !== 3 ? s.bullet : s.selectedBullet} onClick={() => {
                        c.goToSlide(3);
                        setSelected(3);
                    }}>&nbsp;</div>
                </div>
            </div>
            <div className={s.sliderWrapper}>
                <Carousel
                    ref={(ref) => setC(ref)}
                    ssr
                    itemClass="slider-item"
                    autoPlay={true}
                    responsive={responsive}
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                    infinite={true}
                >
                    {articles.map((article, index) => <div key={"article-"+index}>
                        <a href={article.link}
                        target="_blank" rel="noreferrer">
                            <img className={s.sliderItemImg} src={article.thumbnail} alt={article.title} />
                        </a>
                        <div className={s.sliderTitle}><span className={s.sliderTitleSpan}>{article.title}</span></div>
                        <div className={s.date}>{article.currentDate}</div>
                    </div>)}
                </Carousel>
            </div>
        </div>;
}
