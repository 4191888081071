import s from './Idea.module.css'
import cx from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'

export default function IdeaSection(props) {
  const isHeroVideoLoaded = props.isHeroVideoLoaded === true
  const videoRef = useRef(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (isHeroVideoLoaded && videoRef != null && videoRef.current != null) {
      videoRef.current.play()
    }
  }, [isHeroVideoLoaded, videoRef])
  const setVideoLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  return (
    <>
      {isHeroVideoLoaded && (
        <div className={s.sectionWrapper}>
          {loading && <div></div>}
          <div className={s.section}>
            <div className={s.subTitle}>It all starts with an idea. Yours.</div>
            <div className={s.title}>Secure your AI idea on Blockchain</div>
            <a href="https://humansdotai.typeform.com/to/PRILxD2l" className={cx(s.link, 'link', 'small-link')} target="_blank" rel="noreferrer">
              Request
            </a>
            <div className={s.footer}>Humans spark. Technology follows.</div>
          </div>
          <video
              preload="auto"
              autobuffer="true"
              loop
              muted
              autoPlay
              playsInline
              ref={videoRef}
              className={s.video}
              onLoadedData={setVideoLoaded}
          >
            <source
              src="https://humansdotai.fra1.cdn.digitaloceanspaces.com/idea.mp4#t=0.1"
              type="video/mp4"
              data-wf-ignore="true"
            />
          </video>
        </div>
      )}
    </>
  )
}
